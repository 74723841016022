<script setup>
import axios from "axios";
import { reactive } from "vue";

const imageLoaded = reactive({});

const onImageLoad = (offerId) => {
    imageLoaded[offerId] = true;
};

</script>

<script>
// logos plataformas:
import LogoAmazon from '../assets/img/logos/logo-amazon.png';
import LogoMagalu from '../assets/img/logos/logo-magalu.png';
import LogoShein from '../assets/img/logos/logo-shein.png';
import LogoShopee from '../assets/img/logos/logo-shopee.png';
import LogoMercadoLivre from '../assets/img/logos/logo-mercadolivre.png';

export default {
    data() {
        return {
            allOffers: [], // Lista de ofertas carregadas
            currentPage: 1, // Página atual
            totalOffers: 0, // Total de ofertas disponíveis na API
            pageSize: 10, // Número de itens por página
            isLoading: false, // Indicador de carregamento
            isInitialLoad: true, // Indicador de carregamento inicial

            logos: {
                Amazon: LogoAmazon,
                Magalu: LogoMagalu,
                Shein: LogoShein,
                Shopee: LogoShopee,
                Mercadolivre: LogoMercadoLivre
            }
        };
    },
    methods: {
        trackAndRedirect(event, offer) {
            // Ativando o Pixel do Facebook
            if (window.fbq) {
                window.fbq('track', 'InitiateCheckout', {
                    content_name: offer.title,
                    value: offer.price / 100,
                    currency: 'BRL',
                    content_ids: [offer.id],
                    content_type: 'product',
                });
            }

            // Aguarde um pequeno tempo para garantir que o evento seja enviado
            setTimeout(() => {
                window.open(offer.link, "_blank");
            }, 300);

            // Prevenir a navegação padrão do <a>
            event.preventDefault();
        },

        async loadOffers() {
            const StrapiBaseURL = 'https://strapi-185072-0.cloudclusters.net/api';

            // Evitar múltiplas requisições
            if (this.isLoading || (this.allOffers.length >= this.totalOffers && this.totalOffers !== 0)) {
                return;
            }

            this.isLoading = true;

            try {
                // Requisição à API
                const response = await axios.get(`${StrapiBaseURL}/product-offers`, {
                    params: {
                        populate: '*',
                        sort: ['id:desc'],
                        pagination: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                        },
                    },
                });

                const offers = response.data.data || [];
                const pagination = response.data.meta?.pagination || {};

                console.log(offers)
                // Atualiza o total de ofertas
                this.totalOffers = pagination.total || 0;

                // Adiciona as novas ofertas
                offers.forEach((z) => {
                    const imageBaseURL = StrapiBaseURL.replace('/api', '');
                    this.allOffers.push({
                        id: z.id,
                        createdAt: z.attributes.createdAt,
                        title: z.attributes.Title,
                        price: z.attributes.OfferPrice,
                        code: z.attributes.CouponCode,
                        link: z.attributes.AffiliateURL,
                        platform: z.attributes.FromPlatform,
                        isActive: z.attributes.IsOfferActive,
                        installments: z.attributes.InterestFreeInstallments,
                        image: z.attributes.OfferImg?.data[0]?.attributes?.url
                            ? `${imageBaseURL}${z.attributes.OfferImg.data[0].attributes.url}`
                            : '', // Constrói a URL correta da imagem
                        copySuccess: false,
                        showCopy: true,
                        category: z.attributes.Category,
                        description: z.attributes.ProductDescriptionFromAI
                    });
                });

                // Incrementa o número da página para a próxima requisição
                this.currentPage++;
            } catch (error) {
                console.error('Erro ao carregar ofertas:', error);
            } finally {
                this.isLoading = false;
                this.isInitialLoad = false;
            }
        },
        timeAgo(dateString) {
            const currentDate = new Date(dateString);
            const now = new Date();
            const differenceInMillis = now - currentDate;

            const seconds = Math.floor(differenceInMillis / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 1) {
                return `há ${days} dias`;
            } else if (days === 1) {
                return `há 1 dia`;
            } else if (hours > 1) {
                return `há ${hours} horas`;
            } else if (hours === 1) {
                return `há 1h`;
            } else if (minutes > 1) {
                return `há ${minutes} minutos`;
            } else if (minutes === 1) {
                return `há 1 minuto`;
            } else {
                return `agora mesmo`;
            }
        },
        async copyCouponCode(offer) {
            try {
                await navigator.clipboard.writeText(offer.code);

                offer.showCopy = false;
                offer.copySuccess = true;

                setTimeout(() => {
                    offer.copySuccess = false;
                    offer.showCopy = true;
                }, 3000);
            } catch (err) {
                console.error('Erro ao copiar o texto:', err);
            }
        },
        handleScroll() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;

            // Verifica se o usuário está próximo do final da página (90%)
            if (scrollTop + clientHeight >= scrollHeight * 0.9) {
                this.loadOffers();
            }
        },
    },
    mounted() {
        this.loadOffers(); // Carrega a primeira página ao montar o componente
        window.addEventListener('scroll', this.handleScroll); // Adiciona o evento de scroll
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll); // Remove o evento ao desmontar o componente
    },
};
</script>

<template>
    <div v-if="isInitialLoad && allOffers.length === 0" class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 gap-6 mt-8">
        <div
            v-for="index in 3"
            :key="index"
            class="w-full sm:max-w-xs lg:max-w-sm mx-auto bg-white flex flex-col gap-y-2 justify-between items-center pt-4 pb-6 px-4 lg:px-6 rounded-xl border border-slate-300 transition duration-300 ease">
            <!-- Imagem -->
            <div class="pt-6 pb-4">
                <div class="bg-gray-300 animate-pulse w-[230px] object-contain object-center rounded-3xl aspect-square"></div>
            </div>
            <!-- Título -->
            <div class="w-full flex items-center justify-center flex-col">
                <div class="w-[80%] h-3 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[72%] h-3 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
            <!-- Preço -->
            <div class="w-full mt-1 mb-2 flex flex-col justify-center items-center gap-y-1">
                <div class="w-[30%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[48%] h-4 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[30%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
            <!-- Botão de promoção -->
            <div class="mt-2 w-full h-12 rounded-md py-3 px-5 bg-gray-300 animate-pulse"></div>
            <!-- Tempo desde criação -->
            <div class="w-full flex items-center justify-end gap-1 text-xs text-slate-500 mt-2">
                <div class="w-[34%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 gap-5 mt-8">

        <a
            :href="'./oferta/' + offer.id"
            v-for="offer in allOffers"
            :key="offer.id"
            class="w-full sm:max-w-xs lg:max-w-sm mx-auto bg-white flex flex-col gap-y-2 justify-between items-center pt-4 pb-6 px-4 lg:px-6 rounded-xl border border-slate-300 hover:shadow-lg transition duration-300 ease cursor-pointer">
            <!-- Imagem -->
            <div class="pt-6 pb-4 flex justify-center items-center">

                <div v-if="!imageLoaded[offer.id]" class="bg-gray-200 animate-pulse w-[230px] object-contain object-center rounded-3xl aspect-square"></div>

                <img
                    v-if="offer.image"
                    :src="offer.image"
                    :alt="offer.title"
                    class="w-[85%] object-contain object-center rounded-3xl aspect-square"
                    @load="onImageLoad(offer.id)"
                    :style="{ display: imageLoaded[offer.id] ? 'block' : 'none' }" />
            </div>
            <!-- Categoria -->
            <div v-if="offer.category && offer.category !== ''" class="w-full">
                <span class="text-xs inline-block bg-[#f6f0d1] text-[#462d01] font-semibold py-1 px-3 rounded-full">
                    {{ offer.category }}
                </span>
            </div>
            <!-- Título -->
            <h2 class="text-base font-normal text-slate-700 mb-1 line-clamp-3 max-w-[100%] w-full overflow-hidden">{{ offer.title }}</h2>
            <!-- Plataforma -->
            <div class="w-full flex items-center justify-start gap-2 my-1" v-if="offer.platform && offer.platform !== ''">
                <div class="shrink-0">
                    <img
                        v-if="logos[offer.platform]"
                        :src="logos[offer.platform]"
                        :alt="'Logo ' + offer.platform"
                        class="border border-slate-200 rounded-full w-7 h-7 ratio-1x1" />
                </div>
                <div class="flex flex-col gap-0 items-center">
                    <p class="text-xs text-slate-700 mb-0">Oferta da loja: <span class="font-semibold">{{ offer.platform }}</span></p>
                </div>
            </div>
            <!-- Descrição/copy -->
            <div v-if="offer.description && offer.description !== ''" class="mt-2 mb-3 mx-1">
                <div class="text-xs text-[#462d01] font-semibold mb-2">
                    Sobre esse produto:
                </div>
                <div class="flex flex-col gap-2 font-normal text-slate-500 text-xs leading-[1.35rem] line-clamp-5" v-html="offer.description.replace('block', 'line-clamp-5').split('</span>')[0]"></div>
            </div>
            <!-- Preço -->
            <div v-if="false" class="mt-1 mb-2 flex flex-col justify-center items-center gap-y-1">
                <div class="block text-center text-sm text-slate-500 font-normal">
                    A partir de:
                </div>
                <p class="text-[#5e4d37] text-2xl font-bold mb-0">{{ (offer.price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <div class="text-center text-sm text-slate-500 font-normal">
                    <span v-if="offer.installments > 1">Em até {{ offer.installments }}x sem juros</span>
                    <span v-else>À vista</span>
                </div>
            </div>
            <!-- Botão de promoção -->
            <div class="w-full mt-2">
                <a
                    @click="trackAndRedirect($event, offer)"
                    :href="offer.link"
                    target="_blank"
                    style="box-shadow: 0 5px 0 #07a048"
                    class="text-sm inline-flex w-full justify-center items-center rounded-md border border-transparent bg-[#0bbf57] py-3 px-5 font-semibold text-white hover:bg-[#1b8746] hover:text-white focus:outline-none focus:ring-2 focus:ring-[#24b45d] focus:ring-offset-2 disabled:opacity-75 cursor-pointer">
                    Pegar promoção
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 ml-2 -mr-1">
                        <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd"></path>
                        <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd"></path>
                    </svg>
                </a>
            </div>
            <!-- Código de cupom -->
            <div
                v-if="false && offer.code"
                @click="copyCouponCode(offer)"
                class="w-full rounded-lg px-4 py-3.5 border-2 border-dashed border-slate-400 hover:border-teal-600 hover:shadow-md transition duration-300 ease my-2 flex items-center justify-between cursor-pointer">
                <div v-show="offer.showCopy" class="flex justify-between items-center w-full gap-x-1">
                    <div class="coupon-code font-bold text-ellipsis overflow-hidden text-sm">
                        {{ offer.code }}
                    </div>
                    <div class="flex items-center gap-x-1 text-sm text-slate-600 font-normal">
                        Copiar
                    </div>
                </div>
                <div v-if="offer.copySuccess" class="success-copy text-sm font-normal flex h-[20px] items-center justify-center w-full text-slate-700">
                    Copiado com sucesso!
                </div>
            </div>
            <!-- Tempo desde criação -->
            <div class="w-full flex items-center justify-end gap-1 text-xs text-slate-500 mt-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                Publicado {{ timeAgo(offer.createdAt) }}
            </div>
        </a>

        <div
            v-if="isLoading && !isInitialLoad"
            class="w-full sm:max-w-xs lg:max-w-sm mx-auto bg-white flex flex-col gap-y-2 justify-between items-center pt-4 pb-6 px-4 lg:px-6 rounded-xl border border-slate-300 transition duration-300 ease">
            <!-- Imagem -->
            <div class="pt-6 pb-4">
                <div class="bg-gray-300 animate-pulse w-[230px] object-contain object-center rounded-3xl aspect-square"></div>
            </div>
            <!-- Título -->
            <div class="w-full flex items-center justify-center flex-col">
                <div class="w-[80%] h-3 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[72%] h-3 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
            <!-- Preço -->
            <div class="w-full mt-1 mb-2 flex flex-col justify-center items-center gap-y-1">
                <div class="w-[30%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[48%] h-4 bg-gray-300 rounded animate-pulse mb-1"></div>
                <div class="w-[30%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
            <!-- Botão de promoção -->
            <div class="mt-2 w-full h-12 rounded-md py-3 px-5 bg-gray-300 animate-pulse"></div>
            <!-- Tempo desde criação -->
            <div class="w-full flex items-center justify-end gap-1 text-xs text-slate-500 mt-2">
                <div class="w-[34%] h-2 bg-gray-300 rounded animate-pulse mb-1"></div>
            </div>
        </div>

    </div>

    <div v-if="false && isLoading && !isInitialLoad" class="text-center text-gray-500 mt-4">
        Carregando mais ofertas...
    </div>
</template>
